import React, { useCallback, useRef } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

import videoOnIcon from "assets/img/video-on.png";
import videoOffIcon from "assets/img/video-off.png";
import { ControlItem } from "./styled";
import { getDataOptions } from "utils/helpers";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { dispatchError, showErrorInfoBlock } from "../../../../store/zoom/actions";
import store from "../../../../store";
import { errorReasons } from "../../../../utils/constants";


const ReactTooltipStyled1 = styled(ReactTooltip)`
    z-index: 1 !important;
    opacity: 1 !important;
    background-color: #444444 !important;
    color: #ffffff !important;
    border-radius: 15px !important;
`;

const VideoController = ({
                             isStartedVideo, setIsStartedVideo, dispatchError, initializedVideo,
                             zmClient
}) => {
    const { mediaContext: { mediaStream }, startVideo } = useVideoContext();
    const lastClickTimeRef = useRef(0);

    const onCameraClick = useCallback(async () => {
        try {
            if ((Date.now() - lastClickTimeRef.current) < 1000) return;
            if (isStartedVideo) {
                await mediaStream?.stopVideo();
                setIsStartedVideo(false);
            } else {
                await startVideo(mediaStream);
                setIsStartedVideo(true);
            }
            lastClickTimeRef.current = Date.now();
        } catch (e) {
            if (e.type === "VIDEO_USER_FORBIDDEN_CAPTURE") {
                store.dispatch(showErrorInfoBlock(errorReasons["REASON_ACCESS_DENIED"]));
            }
            dispatchError(e);
        }
    }, [mediaStream, isStartedVideo, zmClient]);

    return (
        <>
            <ReactTooltipStyled1
                arrowColor="transparent"
                id={"VideoController"}
                place="top"
                effect="solid"
                className="tooltip1"
                multiline={true}
            >
                Camera
            </ReactTooltipStyled1>
            <ControlItem
                disabled={!initializedVideo}
                data-tip data-for={"VideoController"}
                icon={isStartedVideo ? videoOnIcon : videoOffIcon}
                data-trackable={
                    getDataOptions({
                        view: "VideoController",
                        type: "button",
                        label: isStartedVideo ? "videoOnIcon" : "videoOffIcon"
                    })
                }
                onClick={onCameraClick}
            />
        </>
    );
};

const mapStateToProps = (store) => ({
    zmClient: store.zoom.zmClient,
});

const mapDispatchToProps = {
    dispatchError
};
export default connect(mapStateToProps, mapDispatchToProps)(VideoController);
