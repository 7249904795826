import React, { useCallback } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

import CallEndIcon from "assets/img/call-end-red.png";
import HoverCallEndIcon from "assets/img/call-end.png";
import { endCallAction, partnerClickedEndCallAction, setEndCallClickedAction } from "store/room/actions";
import { getDataOptions } from "utils/helpers";

import { CallControlItem } from "./styled";
import { partnerConnectedAction } from "../../../../store/room/actions";
import { setDisconnectedAction, switchFishbowlViewAction } from "../../../../store/controller/actions";

const ReactTooltipStyled1 = styled(ReactTooltip)`
  z-index: 1 !important;
  opacity: 1 !important;
  width: 90px !important;
  position: absolute;
  background-color: #444444 !important;
  color: #ffffff !important;
  border-radius: 15px !important;
  padding: 5px 12px !important;
`;

const CallMobileController = props => {
    const { zmClient } = props;

    const endCall = useCallback(async () => {
        await zmClient.leave();

        props.setEndCallClickedAction(true);
        props.partnerClickedEndCallAction();
        props.endCallAction();

        if (props.currentSession.roomNumber) {
            window.sessionStorage.setItem(`clicked-end-call`, props.currentSession.roomNumber);
        }
    }, [zmClient]);

    return (
        <>
            <ReactTooltipStyled1
                arrowColor="transparent"
                id={'CallController'}
                place="top"
                effect="solid"
                className="tooltip1"
                multiline={true}
            >
                Leave Call
            </ReactTooltipStyled1>
            <CallControlItem
                disabled={props.showNextPartnerBioPopup}
                data-tip data-for={'CallController'}
                icon={CallEndIcon} hoverIcon={HoverCallEndIcon}
                hide={props.showNextPartnerBioPopup}
                data-trackable={
                    getDataOptions({
                        view: 'CallController',
                        type: 'button',
                        label: 'CallEndIcon'
                    })
                }
                onClick={endCall}
            />
        </>
    )
};

export default connect(
    store => ({
        zmClient: store.zoom.zmClient,
        currentSession: store.controller.currentSession,
        appMode: store.controller.appMode,
        showNextPartnerBioPopup: store.controller.showNextPartnerBioPopup,
        eventId: store.controller.currentEvent.eventId,
    }),
    {
        setEndCallClickedAction,
        endCallAction,
        partnerClickedEndCallAction,
        partnerConnectedAction,
        setDisconnectedAction,
        switchFishbowlViewAction
    }
)(CallMobileController)
