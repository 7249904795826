import React from "react";
import ChatIcon from "assets/img/chat-icon.png";
import { ControlItem } from "./styled";
import { getDataOptions } from "utils/helpers";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import useChatContext from "../../hooks/useChatContext/useChatContext";

const ReactTooltipStyled1 = styled(ReactTooltip)`
    z-index: 1 !important;
    opacity: 1 !important;
    background-color: #444444 !important;
    color: #ffffff !important;
    border-radius: 15px !important;
`;

const ChatController = props => {
    const { isChatWindowOpen, setIsChatWindowOpen } = useChatContext();;

    const toggleChatWindow = () => {
        setIsChatWindowOpen(!isChatWindowOpen);
    };

    return (
        <>
            <ReactTooltipStyled1
                arrowColor="transparent"
                id={'ChatController'}
                place="top"
                effect="solid"
                className="tooltip1"
                multiline={true}
            >
                Text Chat
            </ReactTooltipStyled1>
            <ControlItem
                data-tip data-for={'ChatController'}
                icon={ChatIcon} bgSize="50%"
                data-trackable={
                    getDataOptions({
                        view: 'ChatController',
                        type: 'button',
                        label: 'ChatIcon'
                    })
                }
                onClick={toggleChatWindow}
            />
        </>
    )
};

export default ChatController;
