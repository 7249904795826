import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import produce from "immer";
import ZoomVideo, { ConnectionState } from "@zoom/videosdk";

import store from "store";
import { dispatchError, setInstalledVideoSDK } from "store/zoom/actions";
import { PARTICIPANT_VIDEO_ID, SHARE_CANVAS_ID, Video_360P, Video_720P } from "../../../utils/videoConstants";
import {
    memberJoinConferenceAction,
    setVideoConnectedAction,
    toggleFooterControlsVisibilityAction
} from "store/room/actions";
import { setErrorInfoBlockVisibleAction } from "store/controller/actions";


const mediaShape = {
    audio: {
        encode: false,
        decode: false
    },
    video: {
        encode: false,
        decode: false
    },
    share: {
        encode: false,
        decode: false
    }
};

const mediaReducer = produce((draft, action) => {
    switch (action.type) {
        case "audio-encode": {
            draft.audio.encode = action.payload;
            break;
        }
        case "audio-decode": {
            draft.audio.decode = action.payload;
            break;
        }
        case "video-encode": {
            draft.video.encode = action.payload;
            break;
        }
        case "video-decode": {
            draft.video.decode = action.payload;
            break;
        }
        case "share-encode": {
            draft.share.encode = action.payload;
            break;
        }
        case "share-decode": {
            draft.share.decode = action.payload;
            break;
        }
        case "reset-media": {
            Object.assign(draft, { ...mediaShape });
            break;
        }
        default:
            break;
    }
}, mediaShape);

export default function useZoomClient({ zmClient }) {
    const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
    const [mediaStream, setMediaStream] = useState(null);
    const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);
    const [isSupportGalleryView, setIsSupportGalleryView] = useState(false);
    const [isConnecting, setIsConnecting] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [isFailover, setIsFailover] = useState(false);
    const [loading, setIsLoading] = useState(true);
    const [connectionStatus, setConnectionStatus] = useState("closed");
    const [isStartShareScreenWithVideoElement, setIsStartShareScreenWithVideoElement] = useState(false);
    const timeout = useRef(null);

    const onConnectionChange = useCallback(
        (payload) => {
            switch (payload.state) {
                case ConnectionState.Reconnecting: {
                    setIsLoading(true);
                    setIsFailover(true);
                    setConnectionStatus("connecting");
                    store.dispatch(toggleFooterControlsVisibilityAction(false));

                    if (!timeout || !timeout.current) {
                        timeout.current = setTimeout(() => {
                            window.location.reload();
                        }, 20 * 1000);
                    }

                    break;
                }
                case ConnectionState.Connected: {
                    setConnectionStatus("connected");
                    if (isFailover) {
                        setIsLoading(false);
                    }
                    window.zmClient = zmClient;
                    window.mediaStream = zmClient.getMediaStream();

                    if (timeout && timeout.current) {
                        clearTimeout(timeout.current);
                        setTimeout(()=>{
                            store.dispatch(toggleFooterControlsVisibilityAction(true));
                        }, 2000);
                    }

                    const {
                        currentSession: { videoConnected },
                        errorInfoBlockReasons,
                        errorInfoBlock
                    } = store.getState().controller;

                    if (!videoConnected) {
                        store.dispatch(setVideoConnectedAction(true));
                        store.dispatch(memberJoinConferenceAction());
                    }

                    if (errorInfoBlock) {
                        store.dispatch(setErrorInfoBlockVisibleAction(false, {
                            ...errorInfoBlockReasons, reconnectingWarning: false
                        }));
                    }
                    break;
                }
                case ConnectionState.Closed: {
                    setConnectionStatus("closed");
                    dispatch({ type: "reset-media" });
                    store.dispatch(setVideoConnectedAction(false));

                    if (payload.reason === "ended by host") {
                        console.warn({
                            title: "Meeting ended",
                            content: "This meeting has been ended by host"
                        });
                    }
                    setIsLoading(true);
                    break;
                }
                case ConnectionState.Fail: {
                    console.warn('Zoom Fail', payload);
                    store.dispatch(setVideoConnectedAction(false));
                    setTimeout(()=> {
                        const {currentSession} = store.getState();
                        const roomNumber = currentSession ? currentSession.roomNumber : 'nd';

                        const hasRefreshed = JSON.parse(
                            window.sessionStorage.getItem(`zoom-${roomNumber}-refreshed`) || 'false'
                        );
                        // if not been refreshed yet
                        if (!hasRefreshed) {
                            window.sessionStorage.setItem(`zoom-${roomNumber}-refreshed`, 'true'); // we are now going to refresh
                            return window.location.reload(); // refresh the page
                        } else {
                            setErrorInfoBlockVisibleAction(true, {networkError: true});
                        }
                    }, 1000);
                }
            }
        },
        [isFailover, zmClient, timeout]
    );

    const onMediaSDKChange = useCallback((payload) => {
        const { action, type, result } = payload;
        dispatch({ type: `${type}-${action}`, payload: result === "success" });
    }, []);

    const onDialoutChange = useCallback((payload) => {
        console.log("onDialoutChange", payload);
    }, []);

    const onAudioMerged = useCallback((payload) => {
        console.log("onAudioMerged", payload);
    }, []);

    const onPeerVideoStateChange = useCallback(async (payload) => {
        const zoomSession = zmClient.getMediaStream();
        if (payload.action === "Start") {
            // a user turned on their video, render it
            const targetElement = document.getElementById(`${PARTICIPANT_VIDEO_ID}_${payload.userId}`);
            if (targetElement) await zoomSession.attachVideo(payload.userId, Video_360P, targetElement);
        } else if (payload.action === "Stop") {
            // a user turned off their video, stop rendering it
            await zoomSession.detachVideo(payload.userId);
        }
    }, [zmClient]);

    useEffect(() => {
        store.dispatch(setInstalledVideoSDK());

        return () => {
            ZoomVideo.destroyClient();
        };
    }, []);

    useEffect(() => {
        if (zmClient) {
            zmClient.on("connection-change", onConnectionChange);
            zmClient.on("media-sdk-change", onMediaSDKChange);
            zmClient.on("dialout-state-change", onDialoutChange);
            zmClient.on("merged-audio", onAudioMerged);
            zmClient.on("peer-video-state-change", onPeerVideoStateChange);
        }

        return () => {
            if (zmClient) {
                zmClient.off("connection-change", onConnectionChange);
                zmClient.off("media-sdk-change", onMediaSDKChange);
                zmClient.off("dialout-state-change", onDialoutChange);
                zmClient.off("merged-audio", onAudioMerged);
                zmClient.off("peer-video-state-change", onPeerVideoStateChange);
            }
        };
    }, [zmClient, onConnectionChange, onMediaSDKChange, onDialoutChange, onAudioMerged]);

    const rerenderParticipant = async (userId = null, mainUserId = null) => {
        if (!zmClient) return false;

        const zoomSession = zmClient.getMediaStream();
        if (userId) {
            await zoomSession.detachVideo(userId);
            const targetElement = document.getElementById(`${PARTICIPANT_VIDEO_ID}_${userId}`);
            if (targetElement) await zoomSession.attachVideo(userId, Video_360P, targetElement);
        } else {
            zmClient.getAllUser().forEach(async (user) => {
                if (user.bVideoOn) {
                    const quality = mainUserId === user.userId ? Video_720P : Video_360P;
                    console.log('quality', user.userId, mainUserId, quality);
                    await zoomSession.detachVideo(user.userId);
                    const targetElement = document.getElementById(`${PARTICIPANT_VIDEO_ID}_${user.userId}`);
                    if (targetElement) await zoomSession.attachVideo(user.userId, quality, targetElement);
                }

                if (user.sharerOn) {
                    const targetElement = document.getElementById(`${SHARE_CANVAS_ID}_${user.userId}`);
                    if (targetElement) await zoomSession.startShareView(targetElement, user.userId);
                }
            });
        }
    };

    const zoomConnect = async ({ token, roomName, userName }) => {
        setIsConnecting(true);
        try {
            await zmClient.join(roomName, token, userName);
            const zoomSession = zmClient.getMediaStream();

            console.log('isSupportMultipleVideos', zoomSession.isSupportMultipleVideos());

            setMediaStream(zoomSession);
            setIsSupportGalleryView(zoomSession.isSupportMultipleVideos());
            setIsStartShareScreenWithVideoElement(zoomSession.isStartShareScreenWithVideoElement());
            setIsLoading(false);
            setIsConnecting(false);
            setIsConnected(true);
        } catch (error) {
            store.dispatch(dispatchError(error));
            setIsConnecting(false);
        }
    };

    return {
        mediaContext,
        isConnecting,
        isConnected,
        isSupportGalleryView,
        zoomConnect,
        loading: loading,
        connectionStatus,
        rerenderParticipant,
        isStartShareScreenWithVideoElement,
    };
};
